@import "../../../../scss/variables";

.colContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  .title, .text, .subtitle {
    margin: 0;
  }

  .title {
    color: $gray;
    font-size: 11px;
    font-family: 'ClanPro-WideNews', sans-serif;
    margin-bottom: 3px;
  }

  .subtitle {
    color: $gray;
    font-size: 14px;
    font-family: 'ClanPro-WideNews', sans-serif;
  }

  .text {
    color: $purple;
    font-size: 13px;
    font-family: 'ClanPro-WideNews', sans-serif;
  }

  .limited {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
