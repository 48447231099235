.mapContainer {
  width: 100%;
  height: 100%;

  &.editMode {
    height: 80vh;
  }
}

.map {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.16);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.16);
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.16);
}

.actionBar {
  display: flex;

  &.disabled {
   display: none;
  }

  .toolbar {
    width: 100%;
    justify-content: flex-start !important;

    .filterMenu {
      color: #fff !important;
      margin-left: 50px !important;

      .filterButton {
        margin-right: 0 !important;
        color: #201047 !important;
        border-radius: 5px !important;

        .filterIcon {
          width: 14px;
          height: 14px;
          vertical-align: middle;
          margin-right: 12px;
        }
      }
    }

    .centerMapButton {
      position: absolute !important;
      right: 40px !important;
      color: #FFF !important;
    }

    .settingsMenu {
      position: absolute !important;
      right: 0 !important;
      color: #fff !important;

      .settingsIcon {
        color: #fff !important;
        font-size: 20px;
        margin-top: 3px;
        cursor: pointer;
      }
    }
  }
}
