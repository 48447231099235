@import '../../scss/variables';

.alertContainer {
  color: $purple;
  background-color: $white;
  display: flex;
  flex: 1 auto;
  padding: 16px;
  font-family: 'ClanPro-WideNews', sans-serif;
  align-items: center;
  font-size: 12px;
  border-width: 0 0 4px 0;
  border-style: solid;

  &.info {
    border-bottom-color: $blue;
  }

  &.warning {
    border-bottom-color: $royal-orange;
  }

  &.success {
    border-bottom-color: $green;
  }

  .icon {
    margin-right: 16px;
    width: 40px;
    height: 40px;
  }

  .badgeContent {
    flex: 1 auto;
  }

  strong {
    font-family: 'ClanPro-WideBold', sans-serif;
    margin-right: 6px;
  }

  .actions {
    flex: 1;
  }
}
