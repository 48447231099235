@import '../../../scss/variables';
.toolbarContainer {
  display: flex;
  width: 100%;
  padding: 0 24px;
  align-items: center;
  justify-content: space-between;
  font-family: 'ClanPro-WideBook', sans-serif;
  font-size: 16px;

  > span:last-of-type {
    display: inline-block;
    white-space: nowrap;
    margin-left: 10px;
  }
}
.subHeader {
  font-size: 12px !important;
  font-family: 'ClanPro-WideNews', sans-serif !important;
  display: block;
}
.header {
  display: block;
  font-family: 'ClanPro-WideBook', sans-serif !important;
}

.container {
  margin-right: 48px;
  margin-bottom: 48px;
}

.changeDriverIcon {
  display: inline-block !important;
  margin-right: 10px;
  vertical-align: middle;
  cursor: pointer;

  &:hover {
    opacity: .5;
  }
}

.totalDistance {
  padding: 16px;
  background-color: $gray-light;
  color: $purple;
  font-family: 'ClanPro-WideBold', sans-serif;
  font-size: 14px;
  line-height: 24px;
}

.tooltip {
  font-size: 12px !important;
}
