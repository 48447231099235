.toggleButton {
  border: 1px solid rgba(0,0,0,.12);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  font-family: "ClanPro-WideBold", sans-serif;
  font-size: 10px;
  padding: 0 14px;
}

.disabled {
  opacity: 0.5;
  cursor: default!important;
}

.toggleButton:hover {
  border: 1px solid rgba(0,0,0,.24);
  cursor: pointer;
}

.toggleButtonActive {
  background-color: #201047;
}

.toggleButtonLabel {
  font-family: "ClanPro-WideBold", sans-serif;
  font-size: 14px;
  color: #201047;
  text-transform: capitalize;
  margin-left: 12px;
}

.toggleButtonOrange {
  background-color: #FF8F5E;
}

.toggleButtonGreen {
  background-color: #07FBB1;
}
