@import "../../../scss/variables";

.dialog {
  .paper {
    width: 80vw;
    max-width: 80vw;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 auto;
    padding: 36px 36px 0;

    h1 {
      color: $darker-grey;
    }

    .closeBtn {
      background: none !important;
    }

    .icon {
      font-size: 24px;
    }
  }

  .content {
    padding: 0 36px 42px;

    .remarkTitle {
      color: $dark-grey;

      span {
        font-family: $font-bold;
        margin-right: 6px;
        margin-bottom: 36px;
      }
    }

    .tableContainer {
      margin-top: 36px;
    }
  }
}
