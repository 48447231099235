@import '../../../../../scss//variables';

.listRoot {
    border: 1px solid $dark-grey;
    background-color: $white;
    border-radius: 8px;
    overflow: hidden;

    &:hover {
      border: 1px solid $action;
      background-color: $white;
    }
}

.input {
    display: none!important
}

.collapseWrapper {
    max-height: 120px!important;
    overflow: hidden!important;
    scroll-behavior: auto!important;
}


.container {
    overflow-y: hidden!important;
}

.selected {
    background-color: $white-2!important;
    &:hover {
        background-color: $white-2!important;
    }
}

.menuListItem {
    padding-left: 8px;
}

.gutters {
    padding: 0 8px 0 16px;
}

.disabled {
    background-color: $light;
    border: 1px solid $dark-grey!important;
    border-radius: 8px!important;
}

.listItem {
    border-radius: 8px;
    height: 32px;
    &:hover {
      background-color: $white,
    }
}

.menuHover {
    &:hover {
        background-color: $super-light!important;
        color: $purple!important;
    }

    &.Mui-disabled {
        color: $dark-grey!important;
    }
}
