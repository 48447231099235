.badgeContainer {
  background-color: #FF5351;
  display: inline-block;
  border-radius: 20px;
  padding: 0px 6px;
  margin: 0;
  min-width: 16px;
  text-align: center;
  line-height: 18px;

  &.info {
    background-color: #13d2fc;
    span.badgeLabel {
      color: #fff;
    }
  }

  &.warning {
    background-color: #FF5351;
    span.badgeLabel {
      color: #fff;
    }
  }

  span.badgeLabel {
    color: #FFF;
    font-family: 'ClanPro-WideNews', sans-serif;
    font-size: 10px;
    padding: 0;
    margin: 0;
  }
}

