@import '../../scss/variables';

.container {
  font-family: "ClanPro-WideNews", sans-serif;
  font-size: 18px;
  color: $purple;
  align-items: center;
  display: flex;
  margin: 12px 0;
}

.title {
  margin: 6px 0;
}

.results {
  margin: 6px 0;
  color: $gray
}

.clearFilter {
  margin-left: 36px;
  padding: 9px 18px;
  border-radius: 100px;
  border: none;
  background-color: $white;
  font-size: 12px;
  color: $purple;
  text-decoration: none;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0,0,0,0.12);
  -moz-box-shadow: 0 3px 6px 0 rgba(0,0,0,0.12);
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.12);
}

.iconRed {
  color: $red;
  padding-right: 8px;
}

.clearFilter:hover {
  background-color: $gray-light;
  transition: all 0.1s ease-out;
}

.clearFilter:active:focus {
  -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,0.12);
  -moz-box-shadow: 0 1px 2px 0 rgba(0,0,0,0.12);
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.12);
}
