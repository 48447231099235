@import "../../../scss/variables";

.green, .blue, .red {
  justify-self: flex-end;
  font-size: 12px;
  font-family: 'ClanPro-WideNews', sans-serif;
}

.green {
  color: $green;
}

.blue {
  color: $blue;
}

.red {
  color: $red;
}
