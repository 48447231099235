.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;

    & > * {
      flex: 4;
    }

    .icon, .title {
      color: #ff5351;
    }

    .title, .message {
      text-align: center;
    }

    .icon {
      width: 60px;
      height: 60px;
    }

    .message {
      margin-bottom: 30px;
    }
  }
}
