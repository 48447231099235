.container {
  display: flex;
  width: auto;
  justify-content: space-between;
}

.field {
  width: auto !important;
  border: 1px solid #edf0f1;
  border-radius: 5px;
  padding: 0 19px;
  margin-bottom: 18px;

  input {
    width: 14px !important;
    display: inline-block !important;
    padding: 12px;
    font-family: "ClanPro-WideNews";
    font-size: 14px;
    margin-bottom: 24px;
    outline: none;
  }

  div {
    display: none;
  }
}
