@import "../../scss/variables";

.title {
  color: $purple;
  margin-bottom: 48px;
}

.container {
  display: flex;
  justify-content: space-between;
  min-width: 1300px;

  & > * {
    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    margin: 0 3px;
  }
}

.noResultsContainer {
  width: 100%;
  height: calc(100vh - 151px);
}
