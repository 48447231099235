@import "../../scss/_variables";

.container {
  .title {
    color: $purple;
    margin-bottom: 48px;
  }

  .tabsContainer {
    margin-top: 36px;

    & > div:first-child, & > div:nth-child(2) {
      max-width: 540px !important;
    }

    & > div:first-child {
      background-color: transparent !important;
      white-space: unset;
      flex-wrap: wrap;
    }

    & > div:nth-child(2) > div {
      background-color: transparent !important;
    }

    & > div:last-child {
      margin-top: 12px;
    }

    .tab {
      text-align: left !important;
      color: $gray !important;
      text-transform: none !important;
      font-family: 'ClanPro-WideNews', sans-serif !important;
      width: auto !important;
      margin-right: 24px !important;

      div {
        align-items: flex-start !important;
        white-space: nowrap;
      }

      &.active {
        color: $purple !important;
      }
    }
  }
}
