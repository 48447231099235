@import '../../../../../scss/variables';

.listRoot {
    border: 1px solid #888888;
    background-color: white;
    border-radius: 8px;

    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.32)!important;
      background-color: white;
    }
}

.focused {
    border: 1px solid $action;
    background-color: white;
    border-radius: 8px;
}

.dropDown {
    position: absolute!important;
    z-index: 1;
    width: 47%;
}

.overlay {
    z-index: 2!important;
}

.collapseWrapper {
    max-height: 288px!important;
    overflow: hidden!important;
    scroll-behavior: unset!important;
}

.inputRoot {
    width: -webkit-fill-available;
    text-transform: capitalize;

  > input {
    text-overflow: ellipsis;
    padding-right: 5px;
  }
}

.groupLetter {
    padding: 16px;
    font-weight: bold;
    font-size: 14px;
}

.menuListItem {
    padding-left: 8px;
}

.gutters {
    padding: 0 8px 0 16px;
}

.disabled {
    background-color: #eee;
    border-radius: 8px;

    &:hover {
        background-color: #eee;
    }
}

.listItem {
    border-radius: 8px;
    height: 45px;
    &:hover {
      background-color: white;
    }
}

.menuHover {
    &:hover {
        background-color: $super-light!important;
        color: $purple!important;
    }

    &.Mui-disabled {
        color: #eee;
    }
}

.clearInput {
  width: 25px !important;
  min-width: 0 !important;

  &:hover {
    background-color: transparent !important;
  }
}

.fuel-type-badge {
  > span {
    border: 1px solid rgba(0,0,0,.12);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    font-family: "ClanPro-WideBold", sans-serif;
    font-size: 10px;
    padding: 0 14px;
    text-transform: capitalize;
    margin-right: 5px;
    white-space: nowrap;
  }

  &.fossil {
    > span {
      background-color: #FF8F5E;
    }
  }

  &.electric {
    > span {
      background-color: #07FBB1;
    }
  }
}

.fuel-type-disabled {
  > span {
    opacity: .4;
    cursor: default;
  }
}
