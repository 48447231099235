$white: #ffffff;
$purple: #220C4A;
$blue: #13d2fc;
$blue-2: #F4F7FC;
$blue-3: #03B3D9;
$red: #ff3a37;
$red-2: #FF5351;
$green: #2CE27E;
$green-2: #1ED771;
$yellow: #ffd65a;
$gray: #7d858c;
$gray-2: #cccccc;
$gray-3 : #666666;
$gray-4: #e0e0e0;
$gray-5: #B8B8B8;
$dark-grey: #888;
$darker-grey: #707070;
$gray-light: #f5f5f5;
$light: #ededed;
$super-light: #f4f7fc;
$light-purple: #e2e6ed;
$border-radius: 5px;
$soft-border: 1px solid #0000001f;
$opaque-black: #00000029;
$opaque-black-2: #00000080;
$black-opaque-3: #0000001f;
$royal-orange: #ff8f5e;
$orange: #ff8f5e;
$white-2: #f5f5f5;
$purple-light: #2e1766;
$violet: #8000FF;
$purple-2: #8000FF;
$light-green: #CAF8DF;
$light-blue: #C4F4FE;
$light-yellow: #FFF5D6;
$light-red: #FFD4D3;

$sepia-3: #C99765;

$warning: #ffce00;
$danger: #f04141;
$action: #13d2fc;
$action-disabled: #abeffe;
$faded-orange: #fadfd3;
$faded-gray: #7d7d7d;

$font-bold: "ClanPro-WideBold", sans-serif;
$font-news: "ClanPro-WideNews", sans-serif;
$font-book: "ClanPro-WideBook", sans-serif;
$dark-blue: #24aac8;

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: $gray-4;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-3;
    box-shadow: none;
    border-radius: 8px;
  }
}

@mixin box-shadow {
  box-shadow: 0px 0px 1px rgba(34, 12, 74, 0.12), 0px 1px 1px rgba(34, 12, 74, 0.12), 0px 4px 6px rgba(34, 12, 74, 0.08), 0px 8px 12px rgba(34, 12, 74, 0.06), 0px 16px 24px rgba(34, 12, 74, 0.04);
}
