@import "../../../scss/variables";

.button {
  border-radius: 5px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  font-size: 12px !important;
  font-family: 'ClanPro-WideNews', sans-serif !important;
  color: $white !important;
  background-color: $purple !important;

  &:first-child {
    margin-right: 12px !important;
  }

  &.blue {
    background-color: $blue !important;
  }

  &.green {
    background-color: $green !important;
  }

  &.red {
    background-color: $red !important;
  }

  &.yellow {
    color: $purple !important;
    background-color: $yellow !important;
  }
}
