@import '../../scss/variables';

.title {
  font-family: ClanPro-WideBold, sans-serif;
  font-size: 20px;
  text-transform: capitalize;
  color: $purple;
}

.title-wrapper {
  display: flex;
}

.notifications-wrapper {
  display: flex;
}


.fillHeight {
  height: fit-content;
}

.grow {
  flex-grow: 1;
}

.h-100 {
  height: 100% !important;
}

.board-list-wrapper {
  padding-left: 32px;
  flex: 0 0 650px;
}

.noToursContainer {
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.noToursTitle {
  font-family: "ClanPro-WideBold", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: $purple;
  text-transform: capitalize;
  margin: 0;
}


.noToursContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;

  > img {
    margin-bottom: 4px;
  }

  > h1 {
    font-family: "ClanPro-WideBold", sans-serif !important;
    font-size: 32px;
    color: $purple;
  }

  .body {
    font-family: ClanPro-WideNews, sans-serif !important;
    font-size: 12px;
    color: $dark-grey;
    width: 210px;
    text-align: center;
    margin-bottom: 40px;
  }

  .button {
    border-radius: 8px !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    height: 44px !important;
    min-width: 158px !important;

    > span {
      font-size: 12px !important;
      font-family: ClanPro-WideNews, sans-serif !important;
    }
  }
}

.tourContainer {
  position: relative;
  overflow-x: auto;
  margin: 36px 0 100px;
}

.handoverOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(255,255,255,0.8);
}

.header {
  margin-bottom: 56px;
}


.pageDate {
  font-family: "ClanPro-WideBold", sans-serif !important;
  font-size: 14px;
  color: $purple;
}

.toolbarTitle {
  font-family: "ClanPro-WideBold", sans-serif !important;
  font-size: 24px !important;
  text-transform: capitalize;
}

.time {
  align-items: center;
  font-family: "ClanPro-WideBold", sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #201047;
  text-transform: uppercase;
  margin-top: 0;
}

.optimizeButton {
  position: relative;
  margin-right: 0 !important;
  color: #ffffff !important;
  border-radius: 5px !important;
  background-color: #ff5351 !important;

  & svg {
    color: #ffffff !important;
  }
}

.boardListContainer {
  scroll-behavior: smooth !important;
  height: 45em!important;
  //overflow-y: auto;
  position: relative;

  &::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(100,100,100,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar
  {
    width: 12px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(100,100,100,.3);
    background-color: #ccc;
  }
}

.customPaper {
  margin-bottom: 40px!important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12) !important;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12) !important;
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12) !important;
}

.customBox {
  padding-right: 40px!important;
}

.boxToolbar {
  display:inline-flex;
  justify-content: flex-start;
  margin: 0;
  width: 100%;
}

.optimization-notification {
  width: 487px;
  height: 108px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  display: flex;
  position: absolute;
  top: 15px;
  margin-left: 14px;
  z-index: 1;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-of-type {
      flex: 0 0 48px;
      color: white;
      background-color: $orange;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      font-size: 24px;
    }

    &:last-of-type {
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
    }
  }

  .header-text {
    font-family: ClanPro-WideBold, sans-serif, sans-serif;
    font-size: 14px;
    color: $orange;
    margin-bottom: 12px;
  }

  .sub-text{
    font-family: ClanPro-WideNews, sans-serif, sans-serif;
    font-size: 12px;
    color: $purple;

    em {
      color: $blue;
      font-style: normal !important;
    }
  }

  .close-button {
    position: absolute;
    right: 10px;
    top: 7px;
    color: $gray;
    cursor: pointer;

    &:hover {
      color: $darker-grey;
    }
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
  .boardListContainer {
    scroll-behavior: initial!important;
    height: 50em!important
  }
}

/* Normal Screens ----------- */
@media only screen  and (min-width : 1224px) {
  .boardListContainer {
    scroll-behavior: initial!important;
    height: 55em!important
  }
}

/* Mobile Phones ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .boardListContainer {
    scroll-behavior: initial!important;
    height: 100em!important
  }
}
