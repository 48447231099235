.wrapper {
  padding: 30px;
}

.details {
  text-align: center;

  .icon {
    .text {
      display: block;
      font-family: 'ClanPro-WideBold', sans-serif;
      font-size: 20px;
      color: #201047;
      text-transform: uppercase;
      margin: 20px 0;
    }


    .subDetails {
      font-family: 'ClanPro-WideNews', sans-serif;
      font-size: 14px;
      color: #888;
    }
  }
}

.inputContainer {
  .buttons {
    padding-top: 24px;
    text-align: right;

    .cancelButton {
      margin-right: 10px !important;
      border-radius: 5px !important;
    }

    .changeDriverButton {
      background-color: #FF5351 !important;
      color: #FFF !important;
      border-radius: 5px !important;

      &:hover {
        background-color: darken(#FF5351, 10%) !important;
      }
      &:disabled {
        opacity: .5;
      }
    }
  }
}
