@import "../scss/variables";

.sideBarToggler {
  width: 32px;
  height: 32px;
  position: absolute;
  background: $green;
  color: $purple;
  border-radius: $border-radius;
  top: 42px;
  cursor: pointer;
  transition: all 450ms cubic-bezier(.19,.89,.63,.98);
  right: -16px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: darken($green, 10%);
  }

  > svg {
    width: 16px !important;
    height: 16px;
  }
}
