.table {
  border: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
}

.row {
  display: table-row;
}

.cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.shipment-modal-content {
  margin: 0;
  padding: 0 !important;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.shipment-modal-content .wrapper {
  display: flex;
}

.shipment-modal-content .cart-no .title {
  font-size: 5em;
  padding: 53px;
}

.shipment-modal-content .cart-no .number {
  font-size: 15em;
}

.shipment-modal-content .cart-no {
  width: 50%;
  min-height: 425px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 25px;
  color: #ffffff;
  font-family: "ClanPro-WideBold", cursive;
}

.shipment-modal-content .cart-no .tour-num {
  display: flex;
  flex-direction: column;
}

.shipment-modal-content .cart-no .tour-num :nth-child(2) {
  font-family: "ClanPro-WideNews", cursive;
}

.shipment-modal-content .cart-details {
  width: 50%;
  min-height: 425px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.shipment-modal-content .cart-details .info {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.shipment-modal-content .cart-details .new-address-section {
  background-color: #ff5351;
  border-radius: 5px;
  padding: 12px;
  color: #ffffff;
  font-family: "ClanPro-WideNews", cursive;
  font-size: 14px;
  white-space: nowrap;
}

.shipment-modal-content .cart-details .new-address-section .new-address {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 15px;
  margin-top: 10px;
}

.info .label {
  font-family: "ClanPro-WideBold", cursive;
  color: #201047;
  font-size: 14px;
}

.info .data {
  font-family: "ClanPro-WideNews", cursive;
  color: #201047;
  font-size: 14px;
}

.info .data-address {
  font-family: "ClanPro-WideNews", cursive;
  color: #888888;
  font-size: 14px;
}

.dialog-paper {
  border-radius: 5px !important;
}

.shipment-modal-actions {
  position: absolute;
  padding: 0px !important;
  top: -45px;
}

@keyframes pound {
  from {
    transform: none;
  }
  25% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
  to {
    transform: none;
  }
}

.pound {
  animation: pound 0.325s;
  animation-timing-function: ease-in-out;
  animation-delay: 0.175s;
}

#change-driver-modal .header,
#mark-tour-modal .header {
  background-color: rgb(146, 212, 0);
  height: 25px;
  padding: 15px 10px;
  color: #fff;
  font-size: 1.4em;
  font-weight: bold;
  line-height: 25px;
}

#mark-tour-modal .header {
  background: none;
  color: rgb(207, 0, 32);
  font-size: 1.5em;
  padding: 20px 20px 0;
}

#mark-tour-modal .content p {
  font-size: 1.1em;
}

#change-driver-modal .material-icons.warning {
  position: absolute;
  left: -40px;
  top: -2px;
}

#change-driver-modal .content,
#mark-tour-modal .content {
  padding: 20px;
}

#mark-tour-modal .content {
  padding-top: 10px;
}

#mark-tour-modal .content .checkbox {
  padding-top: 25px;
  display: inline-block;
}
#mark-tour-modal .content .checkbox > div {
  display: inline-table !important;
  width: auto !important;
  vertical-align: middle;
}

#mark-tour-modal .content .checkbox > span {
  color: gray;
  margin-left: -5px;
}

#change-driver-modal .tour-badge {
  display: inline-block;
  min-width: 180px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.3em;
  color: #fff;
  position: relative;
  left: -25px;
}

#change-driver-modal .actions,
#mark-tour-modal .actions {
  background-color: #30322c;
  height: 50px;
}

#mark-tour-modal .actions {
  background: none;
  text-align: right;
  padding: 5px;
}

#change-driver-modal .actions > div,
#mark-tour-modal .actions > div {
  width: 50%;
  display: inline-block;
  margin-top: 7px;
  position: relative;
}

#mark-tour-modal .actions > div {
  width: auto;
}

.planned-tour > span {
  display: inline-block;
  pointer-events: auto;
  width: 50%;
}

.planned-tour > span.label {
  color: #91d300;
  text-align: left;
}

.planned-tour > span.button {
  text-align: right;
}

.disabled-tour > div:not(:last-child) {
  pointer-events: none;
  opacity: 0.5;
}

#change-driver-modal .actions > div:first-child {
  left: 15px;
}

#change-driver-modal .actions > div:last-child {
  left: -25px;
}

#scan-parcel-error-modal .modal-box-content {
  min-width: 725px;
  height: 475px;
}

#scan-parcel-error-modal .modal-box-content .content {
  line-height: normal;
  display: flex;
  height: 100%;
}

#scan-parcel-error-modal .modal-box-content .content > div {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
}

#scan-parcel-error-modal .modal-box-content .error-icon-container {
  background-color: #ff5451;
  color: #fff;
}

#scan-parcel-error-modal .modal-box-content .error-icon-container > svg {
  height: 200px;
  font-family: "ClanPro-WideNews";
  width: auto !important;
  margin-bottom: 50px;
}

#scan-parcel-error-modal
  .modal-box-content
  .error-icon-container
  > .description {
  font-family: "ClanPro-WideBold";
  font-size: 32px;
  text-align: center;
}

#scan-parcel-error-modal .modal-box-content .error-details span {
  font-family: "ClanPro-WideNews";
  font-size: 18px;
  color: #ff5451;
  text-align: center;
}

#scan-parcel-error-modal .modal-box-content .error-details .heading {
  font-size: 22px;
  font-family: "ClanPro-WideBold";
  font-weight: bold;
  margin-bottom: 15px;
}

#scan-parcel-error-modal p {
  text-align: center;
}

#scan-parcel-error-modal .header {
  background-color: rgb(211, 45, 78);
  color: #fff;
  font-size: 48px;
  font-family: "Patua One", sans-serif;
  padding: 24px 10px;
  text-align: center;
}

#scan-parcel-error-modal .header .close {
  display: inline-block;
  float: right;
  margin-right: 20px;
  cursor: pointer;
}

#scan-parcel-error-modal .header .close:hover {
  color: #9b9b9b;
}

#scan-parcel-error-modal .error-msg p {
  font-size: 36px;
  font-family: "Roboto", sans-serif;
  color: #9b9b9b;
  margin: 0;
  font-weight: bold;
}

#scan-parcel-error-modal .error-msg .detail-1 {
  margin-top: 48px;
}

#scan-parcel-error-modal .error-msg .detail-2,
#scan-parcel-error-modal .error-msg .detail-3 {
  margin-bottom: 48px;
}

#scan-parcel-error-modal .error-msg .detail-3 {
  color: #000;
}

.modal-box {
  -webkit-box-shadow: 0 2px 5px 0 rgba(202, 202, 202, 1);
  -moz-box-shadow: 0 2px 5px 0 rgba(202, 202, 202, 1);
  box-shadow: 0 2px 5px 0 rgba(202, 202, 202, 1);
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  z-index: 999;
}

.modal-box.hidden {
  display: none;
}

.modal-box .modal-box-content {
  background-color: white;
  position: absolute;
  width: 550px;
  top: 45%;
  left: 58%;
  transform: translate(-50%, -50%);
}

.danger > button {
  background-color: #d41835 !important;
}

.danger > button span {
  color: #fff !important;
}
.table {
  background-color: transparent !important;
  border: 0 !important;
}
.table-header {
  background-color: #f4f7fc;
  border: 0 !important;
}
.table-header-row {
  border: 0 !important;
  display: flex;
  border-bottom: none;
}
.table-header-column {
  display: flex;
  flex: 1;
  color: #201047;
  font-family: "ClanPro-WideBold", sans-serif;
  align-items: center;
}
.table-body {
  border: 0 !important;
}
.table-body-row {
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  border: none !important;
  height: 50px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}
.table-body-column {
  display: flex;
  flex: 1;
  align-items: center;
}
.table-footer-row {
  background-color: #f4f7fc;
  border: 0 !important;
}
.no-results-container {
  text-align: center;
}
.no-results-header {
  margin-bottom: 18px;
  color: #7d858c;
}
.no-results-content {
  color: #7d858c;
  margin-bottom: 36px;
  font-size: 20px;
  font-family: "ClanPro-WideNews", sans-serif;
}
.page--wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
}
.sidebar--wrapper {
  position: relative;
  background-color: #201047;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 280px;
  width: 280px;
  flex-direction: column;
  transition: all 450ms cubic-bezier(.19,.89,.63,.98);
}
.sidebar--logo {
  margin: 42px 32px 32px 28px;
}
.sidebar--logo > svg {
  width: 180px;
  height: 22px;
}
.sidebar--menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar--menu li.sidebar--menu-alert {
  color: #ff5351;
}

.sidebar--menu li.sidebar--menu-alert svg {
  width: 24px;
  height: 24px;
}

.sidebar--menu li.sidebar--menu-alert:hover {
  color: #ff5351;
}
.sidebar--menu li {
  align-items: center;
  display: flex;
  margin: 0;
  font-family: ClanPro-WideNews;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  height: 56px;
  padding: 0 25px;
  text-transform: capitalize;
}
.sidebar--menu li > span {
  padding-left: 24px;
  flex: 1 auto;
}
.sidebar--menu li.active {
  background-color: rgba(0, 0, 0, 0.2);
  color: #2CE27E;
}
.sidebar--menu li:hover {
  background-color: rgba(0, 0, 0, 0.2);
  background-color: #2C1060;
}

.sidebar--menu li.active > svg > path {
  fill: #2CE27E;
}

.sidebar--menu li.active:before, .sidebar--profile.active:before {
  content: '';
  float: left;
  background-color: #2CE27E;
  width: 4px;
  position: absolute;
  height: 56px;
  left: 0;
}

.sidebar--profile.active:before {
  height: 80px;
}

.sidebar--space {
  flex: 1 auto;
}
.sidebar--profile {
  color: #fff;
  /*border-top: 1px solid rgba(255, 255, 255, 0.2);*/
  /*border-bottom: 1px solid rgba(255, 255, 255, 0.2);*/
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-bottom: 16px;
}
.sidebar--profile:hover {
  background-color: rgba(0, 0, 0, 0.2);
  background-color: #2C1060;
}
.sidebar--profile-text, .sidebar--profile-name {
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  width: 100%;
}

.sidebar--profile-name {
  margin-left: 0;
}

.sidebar--profile-text-header {
  font-family: ClanPro-WideNews;
  font-size: 14px;
}
.sidebar--profile-text-sub-line {
  font-family: ClanPro-WideNews;
  font-size: 12px;
}

.sidebar--profile-language-picker {
  width: auto;
  height: 63px;
  padding: 0 24px 40px 24px;
}
.sidebar--profile-language-picker:after {
  content: '';
  display: block;
  border-bottom: 1px solid #FFF;
  margin-top: 28px;
}

.sidebar--profile-language-picker ul > li.active{
  background-color: #8000FF;
  color: #FFF;
}

.sidebar--profile-language {
  display: flex;
  align-items: center;
  width: 100%;
  font-family: "ClanPro-WideNews", sans-serif;
  font-size: 14px;
}

.sidebar--profile-language > svg {
  margin-right: 8px;
}
