@import "../../../../scss/variables";

@keyframes noTimeFlash {
  0% { color: $purple }
  50% { color: $danger }
  100% { color: $purple }
}

@keyframes almostNoTimeFlash {
  0% { color: $purple }
  50% { color: $warning }
  100% { color: $purple }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .title, .time {
    margin: 0;
  }

  .title {
    font-size: 11px;
    font-family: 'ClanPro-WideNews', sans-serif;
    color: $gray;
  }

  .time {
    font-size: 14px;
    font-family: 'ClanPro-WideNews', sans-serif;
    color: $purple;

    &.noTimeFlash, &.almostNoTimeFlash {
      animation-iteration-count: infinite;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
    }

    &.noTimeFlash {
      animation-name: noTimeFlash;
    }

    &.almostNoTimeFlash {
      animation-name: almostNoTimeFlash;
    }
  }
}
