@import "../../../scss/variables";

.container {
  width: 76px;
  height: 36px;
  margin-left: 10px;
  border-radius: 2px;
  background-color: #FFF;
  cursor: pointer;
}

.selectedBadge {
  display: inline-block;
  color: #FFF;
  background-color: $green;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  float: right;
  margin-right: 12px;
  margin-left: -12px;
  margin-top: 6px;
}
