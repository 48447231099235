@import "../../../scss/variables";

.shipment-state-label {
  font-size: 12px;
  font-family: ClanPro-WideNews;
  padding: 5px 14px;
  border-radius: 5px;

  &.canceled {
    background-color: #ffeae9 !important;
    color: #ff5351 !important;
  }
  &.assigned {
    background-color: #e6fff2 !important;
    color: $green !important;
  }
  &.not-assigned {
    background-color: #e4faff !important;
    color: #13d2fc !important;
  }
  &.expected {
    background-color: #efefef !important;
    color: #a2a2a2 !important;
  }
}
