@import "../../../scss/variables";

$headerHeight: 72px;

.container {
  position: relative;
  padding-top: 0 !important;
  border-radius: $border-radius !important;
  overflow: hidden !important;
  height: 75vh;

  * {
    box-sizing: border-box;
  }

  &:hover .header {
    color: rgb(255,255,255)
  }

  &.blur {
    .flexWrapper {
      filter: blur(1px);
    }
  }

  .loading {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: $headerHeight;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.33);
    z-index: 100;


    .loader, .savingText {
      color: $white;
    }

    .savingText {
      text-align: center;
      font-family: $font-bold;
      font-size: 14px;
      margin-top: 36px;
    }
  }

  .header {
    display: flex;
    align-items: center;
    height: $headerHeight;
    background-color: $purple;
    color: rgba(255,255,255,.5);
    padding: 0 12px;

    .date, .day {
      transition: color 450ms cubic-bezier(0.23, 1, 0.32, 1);
      margin: 0;
    }

    .date {
      font-size: 14px;
      font-family: $font-news;
      margin-bottom: 6px;
    }

    .day {
      font-family: $font-bold;
      font-size: 17px;
    }
  }

  .flexWrapper {
    display: flex;
    flex-direction: column;
    height: calc(100% - #{$headerHeight});
    width: 100%;

    .scrollContainer {
      flex: 1;
      width: 100%;
      padding: 12px;

      &, * {
        scroll-behavior: unset !important;
      }
    }

    .btnContainer {
      padding: 12px;
    }
  }
}
