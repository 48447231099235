@import "../../../scss/variables";

.container {
  width: 100%;
  background-color: $light;
  border-radius: $border-radius;
  overflow: hidden;
  margin: 6px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.driverSet .header {
    background-color: $green;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $dark-grey;
    height: 36px;
    padding: 12px;
    transition: background-color 225ms cubic-bezier(0.23, 1, 0.32, 1);

    p, .icon {
      color: $white;
    }

    p {
      font-family: $font-bold;
      font-size: 14px;
      margin: 0;
    }

    .tourDetailsBtn {
      font-size: 10px;
      font-family: $font-bold;
      padding: 0;
      min-width: 0;
    }
  }

  .remarkContainer {
    padding: 18px 12px 6px 12px;

    p {
      color: $dark-grey;
      margin: 0;

      &.remarkTitle {
        font-family: $font-bold;
      }
    }
  }

  .selectContainer {
    width: 100%;
    padding: 6px 12px 12px;

    .select {
      width: 100% !important;
    }
  }

  .noDriverFooter {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 12px;
    background-color: $red;
    color: $white;

    .icon {
      font-size: 18px;
      margin-right: 6px;
    }
  }
}
