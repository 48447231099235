@import '../../../../../scss/variables';

.tourLabel {
  display: flex;
  > span {
    flex: 0 0 63px;
  }
  > span:nth-child(2) {
    flex: 0 0 74px;
  }
  > span:nth-child(4) {
    flex: 0 0 88px;
  }
  .error {
    color: $red!important
  }
  .labelWrapper {
    font-family: 'ClanPro-WideNews', sans-serif;
    font-size: 10px;
    color: $purple;
    display: flex;
    flex-direction: column;
    margin: 0 2px;
    .label {
      font-family: 'ClanPro-WideBold', sans-serif;
      font-size: 16px;
      color: $purple;
    }
  }
}

.tour-color-indicator {
  width: 13px;
  height: 13px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 5px;
  position: relative;
  top: -1px;
}

.pastDeadline {
  > span:nth-child(2) {
    color: $red!important
  }
}

.tour-label {
  font-family: 'ClanPro-WideBold', sans-serif;
  font-size: 14px;
  color: $purple;
  padding-top: 8px;
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  > svg {
    width: 22px;
    height: 22px;
    margin-left: 8px;
    > path {
      fill: $red;
    }
  }
}

.time-label-container {
  flex: 0 0 98px !important;
  padding-bottom: 10px;

  &:last-of-type {
    flex: 0 0 80px !important;
  }
}

@media (min-width: 1526px) and (min-height: 960px) {
  .tourLabel {
    display: flex;
    > span {
      flex: 0 0 75px!important;
      }
    > span:nth-child(4) {
      flex: 0 0 88px!important;
    }
  }
}