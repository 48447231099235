@import "../../../scss/variables";

.container {
  background-color: $gray-light !important;
  overflow: hidden;

  td, th {
    border-bottom: none;
  }

  th {
    color: $white;
    background-color: $dark-grey;
    font-family: $font-bold;
  }

  tbody {
    td {
      padding-top: 6px;
      padding-bottom: 6px;
    }

    tr:first-child td {
      padding-top: 24px;
    }

    tr:last-child td {
      padding-bottom: 24px;
    }
  }
}
