@import "../../../scss/variables";

.danger {
    background-color: $danger!important;
  }
  .actionsContainer {
    display: flex;
    justify-items: center;
    justify-content: space-evenly;
    margin-top: 20px;
    > .info {
      margin: 0 12px!important;
    }
  }
  
  .tourNo {
    width: 30px;
    height: 30px;
    background: #00ff22;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 5px 0 !important;
    font-family: 'ClanPro-WideBook';
    font-size: 14px;
  }
  
  
  .paperActions {
    padding: 0 12px;
    height: 75px;
    position: absolute;
    bottom: 10%;
    margin-left: 25px;
  }

  .itemRoot {
    padding: 0!important;
    
    &:hover {
      background: $super-light!important;
    }
    div {
      div {
        padding: 5px 0 3px 0 !important;
        position: relative !important;
        text-align: center;
        font-size: 12px;
        border: none !important;
        align-items: center;
      }
    }
  }
  
  .icon {
    top: 0 !important;
    margin: 0 !important;
    height: 100%!important;
    left: 0 !important;
    align-content: center;
    display: inline-grid!important;
    color: white!important;
  }
  
  .listItemContainer {
    padding: 10px!important;
    border-bottom: 1px solid #eee;
  }

  .time {
    margin-left: 8px;
  }

  .eta {
    font-size: 12px!important;
    font-family: 'ClanPro-WideBook', sans-serif!important;
    display: inline!important;
  }

  .listItems {
    background: white;
    position: absolute;
    bottom: 80px;
    overflow: hidden;
    border-radius: 5px;
    width: 50%;
    margin-left: 42px;
  }

  .collapseWrapper {
    max-height: 200px!important;
    overflow: hidden!important;
    scroll-behavior: unset!important;
}

  .addNewTourButton {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    button:first-of-type {
      width: 100%
    }
  }

