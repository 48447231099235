@import "../../scss/variables";

.sidebarLogo {
  margin: 40px 32px 32px 24px;
  text-align: left;

  > div {
    display: block;
    width: 171px;
    height: 35px;
    background: url('../../images/sidemenu/trunkrs-logo.svg');
  }
}

.scrollContainer {
  height: 100%;

  .fixed {
    display: flex;
  }

  .scrollContent {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    background-color: $purple;
    //overflow-y: auto;
    //overflow-x: hidden;
  }
}

.warning {
  position: relative;
  color: $warning !important;

  .icon {
    position: absolute;
    right: 24px;
  }
}

.selector {
  height: 44px;
  background-color: $white;
  border: 2px solid $darker-grey;
  color: $gray;
  font-family: "ClanPro-WideBold", sans-serif;
  font-size: 12px;
  margin: 16px 24px;
  border-radius: 8px;
  padding-left: 16px;

  &:hover {
    border: solid 1px lighten($darker-grey, 0.12);
  }
}

.sidebarProfilePicture {
  flex: 0 0 48px;
  height: 48px;
  border-radius: 50%;
  background-color: $violet;
  font-family: 'ClanPro-WideNews', sans-serif;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 48px;
    height: 48px;
  }
}

.collapsed {
  flex-basis: 96px !important;
  width: 96px !important;

  > div:nth-of-type(2) {
    margin-right: 14px;
    margin-left: 24px;
  }

  .sidebarMenu {
    > li {
      padding-left: 36px;

      > span {
        display: none;
      }

      > div {
        position: relative;
        top: -15px;
        left: -5px;
      }

      > svg {
        flex: 0 0 24px;
      }
    }
  }

  .languagePicker {
    padding-right: 18px;
    > div > div:first-of-type {
      padding: 14px 14px;
      > div > span > span {
        display: none;
      }
    }
    > div > div:last-of-type > ul > li > span > span {
      display: none;
    }
    > div > div > ul > li > span > svg {
      margin-right: 0 !important;
    }

    li {
      padding: 8px 13px;
    }
  }

  .sidebarProfileText {
    display: none;
  }
  .collapsedDropdown {
    height: 46px !important;
    width: 100%;
    margin-left: 0;

    > div:not(:last-of-type) {
      display: none !important;
    }

    > div:last-of-type {
      background-color: $white;
      height: 30px !important;
      line-height: 30px !important;
      width: 54px !important;
      font-family: ClanPro-WideBold, sans-serif;
      font-size: 10px;
      border-radius: 15px;
      display: flex;
      justify-content: center;
    }

    .powerMode {
      color: #fff;
      background-color: $orange !important;
    }
  }

  .hidden {
    display: none !important;
  }
}

.rectangle {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 46px;
    margin: 0 16px 0 16px;
    border-radius: 8px;
    background-color: $purple-light;
  .planMode {
    height: 10px;
    font-size: 10px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: $white;
    text-transform: uppercase;
  }
}

.menuDisabled {
  color: $gray-3 !important;

  &:hover {
    background-color: transparent !important;
  }

  > span {
    > svg {
      vertical-align: middle;
      margin-left: 5px;
      position: relative;
      top: -1px;
    }
  }

  > svg {
    > path {
      fill: $gray-3 !important
    }
  }
}

.tooltip {
  background-color: #FFF !important;
  color: $purple !important;
  font-size: 10px;
  font-family: 'ClanPro-WideNews', sans-serif !important;
  max-width: 180px !important;
  text-align: center;
}

.tooltipArrow {
  color: #FFF !important;
}





