.btn-postal-code.cancelable > button {
    opacity: 0.4;
}

.btn-postal-code.canceled > button {
    background-color: #d32d4e !important;
}

.canceled-label {
    text-transform: uppercase;
    color: #d32d4e !important;
    font-weight: bold;
}

.page {
    margin-bottom: 98px!important;
}
.page-special {
  min-height: auto !important;
  margin-bottom: 32px !important;
}
