@import "../../../scss/variables";

.lockscreenWrapper {
  margin: 0 auto;
  max-width: 450px;
}

.buttonRoot {
  padding: 12px 0 12px 0;
}

.lockscreenButton {
  width: 100%;
}

.disabled {
  background-color: $action-disabled!important;
}

.indigo {
  color: $purple;
}

.banner {
  margin-bottom: 24px;
}
