@import "../../../scss/variables";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  h1, p {
    color: $gray;
  }

  p {
    font-size: 20px;
    font-family: $font-news;
  }
}
