.container {
  display: flex;
  align-items: center;

  .bar, .details {
    flex: 2;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.08);
    padding: 10px 18px 8px;
  }

  .bar {
    transition: all 1s;
  }

  .details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 198px;
    margin-left: 24px;
    height: 48px;
  }
}
