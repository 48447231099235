.noResultsContainer {
  text-align: center;
}
.noResultsHeader {
  margin-bottom: 18px;
  color: #7D858C;
}
.noResultsContent {
  color: #7D858C;
  margin-bottom: 36px;
  font-size: 20px;
  font-family: 'ClanPro-WideNews', sans-serif;
}
