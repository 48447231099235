@import "../../scss/variables";

.dialog {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 500px;

  h3, p {
    color: $purple;
  }

  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 14px !important;
      font-weight: 600;
      font-family: "ClanPro-WideBold", serif;
    }

    .iconButton {
      padding: 0 !important;
      display: flex !important;
      justify-content: flex-end;

      .icon {
        width: 24px;
        height: 24px;
        color: grey;
      }

      & > div {
        width: 24px !important;
      }
    }
  }

  p {
    font-size: 14px !important;
    font-family: "ClanPro-WideNews", sans-serif;
    max-width: 378px;
  }

  .actionContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 6px;

    .actionBtn {
      margin-right: 6px !important;
      border-radius: 5px !important;
    }
  }
}
