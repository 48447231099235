@import "../../scss/variables";

.table {
  width: 100%;

  .row, .column {
    height: 72px !important;
  }

  .column {
    &.address {
      max-width: 250px;
      min-width: 200px;
    }

    &.driver {
      max-width: 124px;
      min-width: 154px;
    }

    &.tour {
      max-width: 80px;
      min-width: 80px;
    }

    &.reason {
      max-width: 300px;
      min-width: 260px;
    }
  }
}

.colContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  .title, .text, .subtitle {
    margin: 0;
  }

  .title {
    color: $gray;
    font-size: 11px;
    font-family: 'ClanPro-WideNews', sans-serif;
    margin-bottom: 3px;
  }

  .text {
    color: $purple;
    font-size: 13px;
    font-family: 'ClanPro-WideNews', sans-serif;
  }

  .subtitle {
    color: $purple;
    font-size: 13px;
    font-family: "ClanPro-WideBook", sans-serif;
    margin-top: 3px;
  }

  .limited {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.spinner {
  justify-self: flex-end;
}
