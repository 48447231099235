@import '../../scss/variables';

.listItem {
  display: block;
  position: relative;
  border: 1px solid rgba(0,0,0,.12);
  box-shadow: 0 2px 4px rgba(0,0,0,.08);
  background-color: white;
  border-radius: 5px;
  width: 320px;
  margin-right: 20px;
  padding: 0 !important;
  float: left;
  overflow: hidden;
}

.editListItem {
  display: block;
  padding: 0 !important;
  float: left;
  width: 100%;
  position: relative;
  max-height: 80vh;
  overflow: auto;
}

.toolbar {
  height: 100%!important;
  color: white!important;
  display: flex!important;
  justify-content: center;
}

.toolbarTitle {
  padding: 0 !important;
  text-overflow: initial!important;
}

.toolbarTitleWrapper {
  border-top-left-radius: 12px;
}

.hideList {
  display: none;
}

.showList {
  display: block;
}


.changeColorButton {
  cursor: pointer;
  padding: 5px;
  display: flex;
  outline: none;
  background-color: $light;
  border-radius: 50%;

  svg {
    color: white;
    width: 20px !important;
    height: 20px;
  }
}

.icon {
  cursor: pointer;
  padding: 5px;
  outline: none;
  display: flex;
  background-color: $light;
  border-radius: 50%;

  svg {
    fill: $purple !important;
    width: 20px !important;
    height: 20px !important;
  }
}

.columns {
  padding-left: 12px;
}

.expandingColumn {
  transition: all 100ms ease-in;
  padding-right: 24px;
}

.grid {
  display: flex;
  justify-content: center;
}

.typography {
  padding-left: 12px;
  color: white!important
}

.colorPicker {
  background-color: #fff;
  border-radius: 6px;
  position: absolute;
  z-index: 3;
  padding: 15px;
  width: 375px;
  margin-left: -373px;
  margin-top: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12) !important;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12) !important;
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12) !important;
}

.choose {
  color: black;
  justify-content: flex-start;
  display: flex;
  font-size: 10px;
}

.cancel {
  color: red;
  justify-content: flex-end;
  display: flex;
  font-size: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}

.tooltip {
  max-width: 140px;
  padding: 25px;
}

.startIcon {
  width: 16px;
  height: 12px;
  margin-bottom: 13px;
}

.buttonLabel {
  text-transform: none;
  height: 15px;
  font-family: "ClanPro-WideNews", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: $dark-grey;
}

.root {
  font-size: 10px!important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16)!important;
  width: 133px;
  height: 38px;
}

.buttonColor {
    background-color: $light!important;
    color: $purple;
    border-radius: 4px !important;
    height: 28px !important;
    line-height: 28px !important;

    span {
      font-family: ClanPro-WideNews, sans-serif !important;
      font-size: 12px !important;
      text-transform: none !important;
    }
}

.gridContainer {
  margin-top: 20px!important;
  justify-content: space-between;
}

.paperContainer {
  padding: 16px !important;
  margin-bottom: 16px;
  overflow: hidden !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12) !important;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12) !important;
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12) !important;
}

.toolTipContainer {
  display: grid !important;
  justify-content: end !important;
  padding-top: 10px !important;
  padding-right: 20%;
}

.boxProductivity {
  padding: 15px 0;
}

.labels {
  font-size: 10px;
  color: #888888;
  text-transform: capitalize;
}
.margin {
  margin-top: 8px;
}

.toolbarGrid {
  display: block!important;
}

.boxIsActive {
  display: grid;
  justify-content: left;
}

.boxInActive {
  display: grid;
  justify-content: center;
}

.tool-box {
  flex: 0 0 110px !important;
  margin-bottom: 16px !important;
}

.driver-autocomplete {
  width: 265px !important;
  right: 16px;

  > ul {
    input {
      padding: 0 5px 0 0 !important;

      &::placeholder {
        position: relative;
        top: 1px
      }
    }
  }

}

.tour-shipment-container {
  flex: 0 0 300px;
  max-width: none !important;
}

.menu-container {
  flex: 0 0 265px;
  max-width: none !important;
}

.warningBorder {
  border: 2px solid $red !important;
}

.hidden {
  opacity: 0.24 !important;
}

.tour-visibility-toggle {
  vertical-align: middle;
  cursor: pointer;
  position: absolute;
  right: 134px;
  z-index: 1;
  background-color: $light;
  border-radius: 50% !important;
  padding: 5px;
  width: 21px;
  height: 21px;

  &:hover {
    color: $blue !important;
  }

  > svg {
    width: 21px !important;
    height: 21px;
  }
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.bermudaNotification {
  margin-top: 16px;
}
