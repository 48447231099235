@import '../../../scss/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  padding-right: 24px;
}

.tour-toolbar-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.tourActionsContainer {
  justify-content: flex-start !important;
  display: block!important;
}

.title {
  font-size: 20px;
  text-transform: uppercase;
  font-family: "ClanPro-WideBold", sans-serif !important;
  font-weight: bold;
}

.notification-box {
  border-radius: 8px !important;
}

.reload {
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: $blue;
  padding: 0;

  span {
    font-size: 12px !important;
    padding: 16px 24px;
    text-transform: capitalize !important;
  }
}

.danger {
  &:hover {
    background-color: darken($danger, 0.12)!important;
  }
}

.success {
  &:hover {
    background-color: darken($green, 0.12)!important;
  }
}

.notification-message {
  width: 100%;
  min-height: 60px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  display: flex;
  z-index: 1;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-of-type {
      flex: 0 0 48px;
      color: white;
      background-color: $orange;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      font-size: 24px;
    }

    &:last-of-type {
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;

      ul {
        padding: 0 0 0 16px !important;
      }

    }
  }

  .header-text {
    font-family: ClanPro-WideBold, sans-serif, sans-serif;
    font-size: 14px;
    color: $orange;
    margin-bottom: 12px;
  }

  .sub-text{
    font-family: ClanPro-WideNews, sans-serif, sans-serif;
    font-size: 12px;
    color: $purple;

    em {
      color: $blue;
      font-style: normal !important;
    }
  }

  .close-button {
    position: absolute;
    right: 10px;
    top: 7px;
    color: $gray;
    cursor: pointer;

    &:hover {
      color: $darker-grey;
    }
  }
}

.success-notification {
  height: auto !important;
  min-height: 55px !important;

  > div {
    &:first-of-type {
      background-color: $green !important;
    }

    &:last-of-type {
      display: flex;
      flex-direction: row !important;

      button {
        flex: 0 0 104px !important;
        > span {
          text-transform: capitalize !important;
        }
      }
    }
  }
}

.callout {
  margin-bottom: 8px;

  &.danger-banner {
    align-items: center;
  }
}
