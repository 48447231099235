@import "../../scss/variables";

.checkmarkcardWrapper {
  background-color: white;
  margin-bottom: 24px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  max-width: 450px;
  box-shadow: 0px 2px 4px #0000001F
}

.checkmarkcardCheckside {
  background-color: #13d2fc;
  padding: 12px;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  box-shadow: 0px 0px 0px 4px rgba(0,0,0,0.12)!important;
}

.checkmarkcardChecksideChecked {
  background-color: $green;
}

.opacity {
  opacity: 0.3
}

.checkmarkcardCheck {
  background: white;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  border: 4px solid rgba(0,0,0,.12);
}

.checkmarkcardCheckChecked {
  background: white url('../../../src/images/check-solid.svg') center center no-repeat;
}

.checkmarkcardTextSide {
  flex: 1;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 24px;
}

.checkmarkcardOwner {
  color: #FF8F5E;
  font-family: "ClanPro-WideBold", sans-serif;
  font-size: 11px;
}

.checkmarkcardTitle {
  color: #201047;
  font-size: 18px;
  font-family: "ClanPro-WideBold", sans-serif;
  padding-bottom: 12px;
}

.checkmarkcardDescripiton {
  color: #888;
  font-size: 12px;
  font-family: "ClanPro-WideNews", sans-serif;
}

.checkmarkcardDescripitonChecked {
  color: $green;
  font-family: "ClanPro-WideBold", sans-serif;
  font-size: 14px;
}

.checkmarkcardCta {
  color: #12D2FC;
  font-size: 12px;
  font-family: "ClanPro-WideNews", sans-serif;
  margin-top: 12px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}


