@import "../../scss/variables";

.wrapper {
  display: flex;
  flex-direction: column;
}

.legend {
  display: block;
  font-family: 'ClanPro-WideBold';
  font-size: 12px;
  color: #201047;
  margin-bottom: 6px;
  flex: 1 auto;
  text-align: left;
}

.container {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  position: relative;

  .textField {
    padding: 12px;
    border: 1px solid #edf0f1;
    font-family: 'ClanPro-WideNews';
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: 24px;
    outline: none;
  }

  .icon {
    position: absolute;
    right: 14px;
    top: 14px;
  }

  &.invalid {
    .textField {
      border-color: #b71c1c;
    }

    .icon {
      color: #b71c1c;
    }
  }

  &.valid {
    .textField {
      border-color: $green;
    }

    .icon {
      color: $green;
    }
  }
}
