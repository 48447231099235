@import '../../../../../scss/variables';

.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    > div:first-of-type {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 32px 0;
        width: 280px;
        text-align: center;
        > span {
            padding-bottom: 16px;
            font-family: 'ClanPro-WideBold', sans-serif;
            font-size: 24px;
            color: $purple;
        }
        > div {
            font-size: 14px;
            color: $purple;
            font-family: 'ClanPro-WideNews', sans-serif;
        }
      }
}

.button {
    background-color: $green !important;
    height: 40px!important;

    > svg {
        margin-left: 16px !important;
    }

   > span {
       color: $purple!important;
       font-size: 14px!important;
       font-family: 'ClanPro-WideNews', sans-serif!important;
   }
   &:hover {
    background-color: darken($green, 10%)!important;
   }
}
