@import "../SharedModalStyles";

.detailsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  padding: 24px;
  border: $soft-border;
  border-radius: $border-radius;
  margin: 12px 0 18px;

  .detail {
    margin-top: 18px;

    &:first-child {
      margin-top: 0;
    }

    .title, .text, .subtitle {
      margin: 0;
    }

    .title {
      color: $gray;
      font-size: 11px !important;
      font-family: 'ClanPro-WideNews', sans-serif;
      margin-bottom: 3px;
    }

    .text, .subtitle {
      color: $purple;
      font-size: 13px !important;
    }

    .text {
      font-family: 'ClanPro-WideNews', sans-serif;
    }

    .subtitle {
      font-family: "ClanPro-WideBook", sans-serif;
      margin-top: 3px;
    }
  }
}
