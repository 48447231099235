@import "../../../scss/variables";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 130px;
  position: absolute;
  border-radius: 50%;
  background: rgba(0,0,0,0.8);
}

.icon {
  color: $green;
  position: absolute;
}

.spinner {
  height: 130px;
  position: absolute;
  width: 130px;
  -webkit-animation: loader-1-1 4.8s linear infinite;
  animation: loader-1-1 4.8s linear infinite;

  span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 130px;
    width: 130px;
    clip: rect(0, 130px, 130px, 65px);
    -webkit-animation: loader-1-2 1.2s linear infinite;
    animation: loader-1-2 1.2s linear infinite;

    &::after {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 130px;
      width: 130px;
      clip: rect(0, 130px, 130px, 65px);
      border: 5px solid $green;
      border-radius: 50%;
      -webkit-animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
      animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
    }
  }
}

@-webkit-keyframes loader-1-1 {
  0%   { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes loader-1-1 {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@-webkit-keyframes loader-1-2 {
  0%   { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(220deg); }
}

@keyframes loader-1-2 {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(220deg); }
}

@-webkit-keyframes loader-1-3 {
  0%   { -webkit-transform: rotate(-140deg); }
  50%  { -webkit-transform: rotate(-160deg); }
  100% { -webkit-transform: rotate(140deg); }
}

@keyframes loader-1-3 {
  0%   { transform: rotate(-140deg); }
  50%  { transform: rotate(-160deg); }
  100% { transform: rotate(140deg); }
}
