@import '../../scss/variables';

.wrapper {
  background: white;
  margin-top: 24px;
  border-radius: 5px;
  padding: 24px;

  .infoRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 24px;

    .driverPosition {
      font-family: "ClanPro-WideBook", sans-serif;
      font-size: 10px;
      color: $gray;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-left: 12px;

      span {
        margin-bottom: 6px;
      }

      .box {
        border: 1px solid $light;
        border-radius: 5px;
        padding: 6px 12px;
        font-family: "ClanPro-WideBold", serif;
        font-size: 12px;
        color: $purple;

        a, a:active, a:focus {
          font-size: 12px;
          font-family: "ClanPro-WideBold", serif;
          color: $purple;
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
        }
      }
    }

    .currentPosition {
      margin-right: 12px;
      color: $gray;
    }

    .driverInfo {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h1 {
        font-family: "ClanPro-WideBold", serif;
        font-size: 18px;
        color: $purple;
      }
      h2 {
        font-family: "ClanPro-WideBook", sans-serif;
        font-size: 12px;
        color: $gray;
      }

      .driverPhoneNumber {
        text-decoration: none;
        color: $green;

        & > svg {
          margin-right: 6px;
        }
      }
    }
  }

  .tourRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .time {
      font-family: "ClanPro-WideBold", serif;
      font-size: 12px;
      color: $purple;
      width: 50px;
    }

    .stopBar {
      margin: 0 12px;
      flex: 1;
      background-color: $super-light;
      border-radius: 5px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .stop {
        height: 12px;
        width: 12px;
        border-radius: 12px;
        background-color: $blue;
        position: relative;
        cursor: pointer;

        &.delivered {
          background-color: $green;
        }
        &.deliveredToNeighbour {
          border: 1px solid $green;
          background: $white;
        }
        &.notDelivered {
          background-color: $red;
        }
        &.current {
          background-image: url(./quickTour/assets/star.svg);
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 10px 10px;
          width: 16px;
          height: 16px;
          border-radius: 16px;
        }
        &.notScanned {
          background: $gray;
        }

        &:hover {
          .overlay {
            display: block;
          }
        }

        .overlay {
          display: none;
          position: absolute;
          background: $white;
          border: 5px;
          padding: 6px;
          width: 120px;
          top: -60px;
          left: -60px;
          border-radius: 5px;
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12) !important;

          &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: $white transparent transparent transparent;
          }

          h1 {
            font-family: "ClanPro-WideBold", serif;
            font-size: 10px;
            color: $purple;
            margin: 0;
          }

          p {
            margin: 0;
            font-family: "ClanPro-WideBook", sans-serif;
            font-size: 8px;
            color: $gray;
          }
        }
      }
    }
  }
  .positionRow {
    margin: 12px 62px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    padding: 0;

    .position {
      margin: 0;
      font-family: "ClanPro-WideBook", sans-serif;
      font-size: 8px;
      color: $gray;
      width: 12px;
      text-align: center;

      &.current {
        width: 16px;
      }
    }
  }
}
.boxShadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12) !important;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12) !important;
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12) !important;
}

.noTours {
  color: $purple;
  margin-top: 30px;
}

.legend {
  display: flex;
  padding: 12px;
  background: #f4f7fc;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-family: "ClanPro-WideBook", sans-serif;
  font-size: 10px;
  color: #7d858c;

  .stop {
    height: 12px;
    width: 12px;
    border-radius: 12px;
    background-color: $blue;
    position: relative;
    cursor: pointer;
    margin: 0 6px 0 12px;

    &.delivered {
      background-color: $green;
    }

    &.deliveredToNeighbour {
      border: 1px solid $green;
      background: $white;
    }

    &.notDelivered {
      background-color: $red;
    }

    &.current {
      background-image: url(./quickTour/assets/star.svg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 8px 8px;
      border-radius: 12px;
    }

    &.notScanned {
      background: $gray;
    }
  }
}
