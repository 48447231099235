@import '../../../scss/variables';
$bar-height: 30px;

.menu {
  margin: 12px 0;
  padding: 12px 0 12px 0;

  &:hover {
    background-color: $light;
  }
}

.bermudaIndicator {
  background-color: $gray-light;
  padding: 16px 0 16px 0;
  .dragHandleButton {
    background-color: $red-2 !important;
    color: #FFF !important;
  }
}

.item {
  margin: 10px 0 0 -15px!important;
  margin-left: 0px!important;
  > div {
    > div {
      font-size: 12px;
      padding: 20px 56px 16px 72px;
      margin: 12px;
    }
  }
  &.multiColli {
    padding-bottom: $bar-height !important;
    margin-left: 0px!important;
  }
}

.moreIcon {
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  cursor: pointer;
}


.dragHandle {
  color: #201047;
  font-family: 'ClanPro-WideBold' !important;
  font-size: 20px;
  background-color: $light;
  text-align: center;
  cursor: move;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 48px;
  width: 48px;
  display: inline-flex;
  margin-left: 23px ;
  margin-right: 16px;
  vertical-align: middle;
}

.hover {
  background: $dark-grey;
}

.gridMenuItem {
  display: flex;
  padding-left: 25px;
}

.address {
  font-family: ClanPro-WideBold, sans-serif !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
  color: #201047;
  font-size: 12px;
}

.estimation {
  font-family: ClanPro-WideNews, sans-serif;
  color: $purple;
  font-size: 12px;
  padding-top: 8px;
  padding-right: 16px;
  float: right;
}

.barPositioning {
  margin-top: 8px;
  margin-left: 85px;
}

.boxRoot {
  border: 1px solid #8888;
  margin: 10px;
  border-radius: 8px;
  background: white;
  overflow: hidden;
}

.listTitle {
  font-weight: 900;
  padding: 5px;
  color: #201047;
  margin-left: 8px;
}

.listItemContainer {
  padding: 10px;
  border-bottom: 1px solid #eee;
  &:hover {
     background: $super-light;
  }
}

.shipmentTime {
  display: inline-flex;
  margin-left: 8px;
}

.listRoot {
  background-color:#eeee;
  min-height: 80px;
}

.eta {
  font-size: 12px!important;
  font-family: 'ClanPro-WideBook', sans-serif!important;
}

.icon {
  top: 0 !important;
  margin: 0 !important;
  height: 100%!important;
  left: 0 !important;
  align-content: center;
  text-align: center;
  font-family: 'ClanPro-WideBold';
  display: inline-grid!important;
  color: white!important;
}

.itemRoot {
  padding: 0!important;
}

.customerInfo {
  display: inline-grid;
  vertical-align: middle;

  > span {
    font-family: ClanPro-WideNews, sans-serif;
    color: $purple;
  }

}

.pull-right {
  display: flex;
  justify-content: flex-end;
}

.more-icon-container {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  left: -15px;
  top: -6px;

  > svg {
    color: $dark-grey !important;
    height: 34px !important;
    width: 34px !important;
  }

}
