.modal {
  z-index: 1000 !important;
}

.modalContent {
  width: 85vw !important;
  max-width: 85vw !important;
}

.modalBody {
  padding: 0 !important;
}

.modalPaper {
  overflow: hidden;
  border-radius: 3px !important;
}

.container {
  display: flex;
}

.listBox {
  flex: 2;
  max-width: 380px;
}

.mapBox {
  flex: 2;
}

.closeButton {
  position: absolute !important;
  z-index: 1112;
  top: -50px;
  right: 0;
}
