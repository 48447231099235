@import "../../../../scss/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .title, .doneText {
    margin: 0;
  }

  .title {
    font-size: 11px;
    font-family: 'ClanPro-WideNews', sans-serif;
    color: $gray;
  }

  .doneText {
    font-size: 14px;
    font-family: 'ClanPro-WideNews', sans-serif;
    color: $purple;
  }
}
