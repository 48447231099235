.paper {
  background-color: #201047 !important;
  text-align: center !important;
  border-radius: 5px !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12) !important;
}

.content {
  width: 400px !important;

  .logo {
    margin-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 150px;
    height: 70px;
  }

  .text {
    padding: 24px;
    text-transform: capitalize;
    font-weight: bold;
    color: #fff;
  }

  .loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
