@import "../../../scss/variables";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pageTitle {
    color: $purple;
  }

  .loadingContainer {
    display: flex;
    align-items: center;

    .spinner {
      margin-right: 12px;
    }
  }

  .updated {
    color: $purple;
  }
}
