@import '../../scss/variables';

.overview {
  background: $white 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px $opaque-black;
  border-radius: 5px;
  padding: 32px 32px 0 32px;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 32px;

  .filter {
    margin-right: 38px;
    margin-bottom: 32px;

    &:last-child {
      margin-right: 0;
    }

    .title {
      font-family: "ClanPro-WideBold", sans-serif;
      font-size: 14px;
      color: $purple;
      margin-bottom: 18px;
    }

    .items {
      display: flex;
      flex-direction: row;
      width: 325px;
      flex-wrap: wrap;
      margin-bottom: 18px;
      border-radius: 5px;
      overflow: hidden;

      .item {
        width: 10%;
        height: 24px;
        display: block;
        background: $purple 0 0 no-repeat padding-box;
        margin: 0;

        &.greenItem {
          background-color: $green;
        }

        &.redItem {
          background-color: $red;
        }

        &.defaultItem {
          background-color: $purple;
        }
      }
    }

    .summary {
      background: $white 0 0 no-repeat padding-box;
      border: 1px solid $opaque-black-2;
      border-radius: 5px;
      color: $purple;
      font-family: "ClanPro-WideNews", sans-serif;
      font-size: 14px;
      padding: 12px;
      display: inline-block;

      div {
        display: inline-block;

        &:first-child {
          border-right: solid 1px $gray;
          padding-right: 12px;
        }

        &:last-child {
          padding-left: 12px;
        }
      }
    }
  }
}
